<template>
  <div class="client-myTab3">
    <div class="client-header">
      <span> {{lang.latestTele}} </span>
      <span>
        <el-input
          v-model="sValue"
          :placeholder="lang.searchInput"
          suffix-icon="el-icon-search"
        ></el-input>
      </span>
    </div>
    <els-table
      ref="tabTable"
      style="height: 500px"
      :column="column"
      :data="latestTelemetryData"
      :showIndex="false"
      :showMenu="false"
      :showPagination="false"
    >
    </els-table>
  </div>
</template>
<script>
import elsTable from "../../../components/els-table/main.vue";
import mixinsI18n from "@/mixins/i18n.js"
export default {
  name: "client-myTab3",
  mixins: [mixinsI18n],
  components: { elsTable },
  props: {
    isData: Object,
  },
  data: () => {
    return {
      PAGE_NAME: "clientManagePage",
      sValue: "",
      total: 0,
      // latestTelemetryData: [],
      latestTelemetrysource: {},
      savelatestTelemetryData: [],
    };
  },
  watch: {
    sValue(v) {
      this.$refs.tabTable.page.pageNum = 1;
      if (!!v) {
        const s = this.savelatestTelemetryData;
        const k = Object.keys(s).filter((els) => {
          return (
            els.indexOf(v) > -1 ||
            this.savelatestTelemetryData[els][0][1].indexOf(v) > -1
          );
        });
        if (k.length > 0) {
          let r = {};
          k.forEach((ks) => {
            if (s[ks]) {
              r[ks] = s[ks];
            }
            this.latestTelemetrysource = r;
          });
        } else {
          this.latestTelemetrysource = [];
        }
      } else {
        this.latestTelemetrysource = this.savelatestTelemetryData;
      }
    },
  },
  computed: {
    latestTelemetryData() {
      const res = this.latestTelemetrysource;
      const r = Object.keys(res).map((k) => ({
        key: k,
        value: res[k][0][1],
        time: res[k][0][0],
      }));
      r.sort((a, b) => {
        return a.time < b.time ? 1 : -1;
      });
      return r;
    },
    column() {
      const data = [
      {
        prop: "time",
        label: this.lang.lastUpdate,
        valueType: "date",
        width: "200px",
        sortable: true,
      },
      { prop: "key", label: this.lang.key ,sortable: true,},
      { prop: "value", label: this.lang.value,sortable: true,},
    ]
    return data
    }
  },
  methods: {
    connect() {
      try {
        const { id, entityType } = this.isData.nId;
        this.$socket.getData(
          id,
          entityType,
          "LATEST_TELEMETRY",
          this.handleCallback
        );
      } catch (e) {
        console.error(e);
      }
    },
    handleCallback(res = {}) {
      this.latestTelemetrysource = { ...this.latestTelemetrysource, ...res };
      this.savelatestTelemetryData = this.latestTelemetrysource;
      //
      // this.latestTelemetryData = Object.keys(this.latestTelemetrysource).map(
      //   (k) => ({
      //     key: k,
      //     value: res[k][0][1],
      //     time: res[k][0][0],
      //   })
      // );
    },
    utilFilter(list, key) {
      return list.filter(
        (item) => Object.values(item).join(",").indexOf(key) != -1
      );
    },
  },
  mounted() {
    this.connect();
  },
  destroyed() {
    this.$socket.reset();
  },
};
</script>
<style scoped lang="scss">
::v-deep .el-input__icon {
  line-height: 32px;
}
::v-deep .el-input__inner {
  height: 32px !important;
  line-height: 32px !important;
}
.client-myTab3 {
  .client-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 20px;
  }
}
</style>
